import { Reference, gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ActionItem, ActionMenu, AtomSpinner, AutoOpen, BirthdayPicker, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Choice, CircularSpinner, Colors, ConfirmModal, FormModal, FormModalValueProvider, HasEmployeePermission, Icon, Icons, InfoPanel, Link, ModalLauncher, NoPermission, NotFound, PageButtons, PhoneInputField, Row, SingleSelect, StandardAlert, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, TextArea, TextEditor, TextField, View, generateId, useAlertState, useAuthState, useForm } from "@barscience/global-components";
import { ToolbarButtonConfig } from "@barscience/global-components/dist/typings/TextEditor/Toolbar/Toolbar";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TimeAgo from 'react-timeago';

/* Get User Profile Query */
const USER_PROFILE_QUERY = gql`
query getUserProfileForAdmins($id: ID!) {
  user(id: $id) {
    id
    firstName
    lastName
    email
    phone
    address {
      streetAddress
      city
      state
      zipcode
    }
    dateOfBirth
    isInactive
    created
    lastLoginTimestamp
    lastLoginIp
    isBSEmployee
    isSupportAgent
    org {
      id
      name
      primaryContact {
        id
      }
      billingContact {
        id
      }
    }
    productRoles {
      id
    }
  }
}
`;

type UserProfileResponse = {
  user: User | null;
}

type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: Address | null;
  dateOfBirth: string;
  isInactive: boolean;
  created: string;
  lastLoginTimestamp: string | null;
  lastLoginIp: string | null;
  isBSEmployee: boolean;
  isSupportAgent: boolean;
  org: Org | null;
  productRoles: {
    id: string;
  }[];
}

type Org = {
  id: string;
  name: string;
  primaryContact: {
    id: string;
  } | null;
  billingContact: {
    id: string;
  } | null;
}

type Address = {
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
}

/* Get User Notes Query */
const USER_NOTES_QUERY = gql`
query getUserAdminNotes($userId: ID!) {
  userNotes(userId: $userId) {
    id
    content
    created
    createdBy {
      id
      firstName
      lastName
    }
    lastUpdated
    lastUpdatedBy {
      id
      firstName
      lastName
    }
  }
}
`;

type UserNotesResponse = {
  userNotes: UserNote[];
}

type UserNote = {
  id: string;
  content: string;
  created: string;
  createdBy: {
    id: string;
    firstName: string;
    lastName: string;
  };
  lastUpdated: string | null;
  lastUpdatedBy: {
    id: string;
    firstName: string;
    lastName: string;
  } | null;
}

/* Get User Change Logs Query */
const USER_CHANGE_LOGS_QUERY = gql`
query getUserChangeLogs($id: ID!, $page: Int!) {
  userChangeLogs(id: $id, page: $page) {
    logs {
      timestamp
      description
    }
    pages
  }
}
`;

type UserChangeLogsResponse = {
  userChangeLogs: {
    logs: UserChangeLog[];
    pages: number;
  } | null;
}

type UserChangeLog = {
  timestamp: string;
  description: string;
}

/* Request Password Reset Mutation */
const REQUEST_PASSWORD_RESET = gql`
mutation requestPasswordReset($email: String!) {
  success: requestPasswordReset(email: $email)
}
`;

type RequestPasswordResetResponse = {
  success: boolean;
}

/* Force Change Password Mutation */
const FORCE_CHANGE_PASSWORD = gql`
mutation forceChangeUserPassword($input: ChangePasswordInput!) {
  success: changePassword(input: $input)
}
`;

type ForceChangePasswordResponse = {
  success: boolean;
}

type ForceChangePasswordInput = {
  password: string;
}

/* Edit Name Mutation */
const EDIT_NAME = gql`
mutation editUserName($id: ID!, $firstName: String!, $lastName: String!) {
  editUserName(id: $id, firstName: $firstName, lastName: $lastName) {
    id
    firstName
    lastName
  }
}
`;

type EditNameResponse = {
  editUserName: {
    id: string;
    firstName: string;
    lastName: string;
  };
}

type EditNameInput = {
  firstName: string;
  lastName: string;
}

/* Edit Email Mutation */
const EDIT_EMAIL = gql`
mutation editUserEmail($id: ID!, $email: String!) {
  editUserEmail(id: $id, email: $email) {
    id
    email
  }
}
`;

type EditEmailResponse = {
  editUserEmail: {
    id: string;
    email: string;
  };
}

type EditEmailInput = {
  email: string;
}

/* Edit Phone Mutation */
const EDIT_PHONE = gql`
mutation editUserPhone($id: ID!, $phone: String!) {
  editUserPhone(id: $id, phone: $phone) {
    id
    phone
  }
}
`;

type EditPhoneResponse = {
  editUserPhone: {
    id: string;
    phone: string;
  };
}

type EditPhoneInput = {
  phone: string;
}

/* Edit Birthdate Mutation */
const EDIT_BIRTHDATE = gql`
mutation editUserBirthdate($id: ID!, $dateOfBirth: String!) {
  editUserBirthdate(id: $id, dateOfBirth: $dateOfBirth) {
    id
    dateOfBirth
  }
}
`;

type EditBirthdateResponse = {
  editUserBirthdate: {
    id: string;
    dateOfBirth: string;
  };
}

type EditBirthdateInput = {
  dateOfBirth: string;
}

/* Edit Address Mutation */
const EDIT_ADDRESS = gql`
mutation editUserAddress($id: ID!, $streetAddress: String!, $city: String!, $state: String!, $zip: String!) {
  editUserAddress(id: $id, streetAddress: $streetAddress, city: $city, state: $state, zip: $zip) {
    id
    address {
      streetAddress
      city
      state
      zipcode
    }
  }
}
`;

type EditAddressResponse = {
  editUserAddress: {
    id: string;
    address: Address;
  };
}

type EditAddressInput = {
  streetAddress: string;
  city: string;
  state: string;
  zipcode: string;
}

/* Create Note Mutation */
const CREATE_NOTE = gql`
mutation createUserAdminNote($userId: ID!, $content: String!) {
  createUserNote(userId: $userId, content: $content) {
    id
    content
    created
    createdBy {
      id
      firstName
      lastName
    }
    lastUpdated
    lastUpdatedBy {
      id
      firstName
      lastName
    }
  }
}
`;

type CreateNoteResponse = {
  createUserNote: UserNote | null;
}

type CreateNoteInput = {
  content: string;
}

/* Edit Note Mutation */
const EDIT_NOTE = gql`
mutation editUserAdminNote($id: ID!, $content: String!) {
  editUserNote(id: $id, content: $content) {
    id
    content
    created
    createdBy {
      id
      firstName
      lastName
    }
    lastUpdated
    lastUpdatedBy {
      id
      firstName
      lastName
    }
  }
}
`;

type EditNoteResponse = {
  editUserNote: UserNote | null;
}

/* Delete Note Mutation */
const DELETE_NOTE = gql`
mutation deleteUserNote($id: ID!) {
  success: deleteUserNote(id: $id)
}
`;

type DeleteNoteResponse = {
  success: boolean;
}

/* Get Org Plans Query */
const GET_ORG_PLANS = gql`
query getPlansForOrgUserManagement($orgId: ID!) {
  org(id: $orgId) {
    plans {
      plan {
        id
        name
        type
        cost
        costType
        userLimit
        product {
          id
          name
          roles {
            id
            name
            description
            isBillable
          }
        }
      }
      countBillableUsers
    }
  }
}
`;

type GetOrgPlansResponse = {
  org: {
    plans: {
      plan: Plan;
      countBillableUsers: number;
    }[];
  } | null;
}

type Plan = {
  id: string;
  name: string;
  type: 'MONTHLY' | 'ANNUAL';
  cost: string;
  costType: 'FLAT' | 'PER_USER';
  userLimit: number | null;
  product: Product;
}

type Product = {
  id: string;
  name: string;
  roles: ProductRole[];
}

type ProductRole = {
  id: string;
  name: string;
  description: string | null;
  isBillable: boolean;
}

/* Add Product Role */
const ADD_PRODUCT_ROLE = gql`
mutation addUserProductAccess($userId: ID!, $productId: ID!, $roleId: ID!) {
  addUserProductAccess(userId: $userId, productId: $productId, roleId: $roleId) {
    id
    name
    description
  }
}
`;

type AddProductRoleResponse = {
  addUserProductAccess: ProductRole | null;
}

type AddProductRoleInput = {
  productId: string;
  roleId: string;
}

/* Edit Product Role */
const EDIT_PRODUCT_ROLE = gql`
mutation editUserProductAccess($userId: ID!, $productId: ID!, $roleId: ID!) {
  editUserProductAccess(userId: $userId, productId: $productId, roleId: $roleId) {
    id
    name
    description
  }
}
`;

type EditProductRoleResponse = {
  editUserProductAccess: ProductRole | null;
}

/* Remove Product Role */
const REMOVE_PRODUCT_ROLE = gql`
mutation removeUserProductAccess($userId: ID!, $productId: ID!) {
  success: removeUserProductAccess(userId: $userId, productId: $productId)
}
`;

type RemoveProductRoleResponse = {
  success: boolean;
}

/* Delete User Mutation */
const DELETE_USER = gql`
mutation deleteUserForAdmins($id: ID!) {
  success: deleteUser(id: $id)
}
`;

type DeleteUserResponse = {
  success: boolean;
}

type DeleteUserInput = {
  verification: string;
}

/* Add User To Org */
const ADD_USER_TO_ORG = gql`
mutation assignUserToOrg($userId: ID!, $orgId: ID!) {
  assignUserToOrg(userId: $userId, orgId: $orgId) {
    id
    org {
      id
    }
  }
}
`;

type AddUserToOrgResponse = {
  assignUserToOrg: {
    id: string;
    org: {
      id: string;
    } | null;
  } | null;
}

type AddToOrgInput = {
  orgId: string;
}

/* Remove User From Org */
const REMOVE_USER_FROM_ORG = gql`
mutation removeUserFromOrg($userId: ID!) {
  removeUserFromOrg(userId: $userId) {
    id
    org {
      id
    }
  }
}
`;

type RemoveUserFromOrgResponse = {
  removeUserFromOrg: {
    id: string;
    org: {
      id: string;
    } | null;
  } | null;
}

/* Deactivate User Mutation */
const DEACTIVATE_USER = gql`
mutation deactivateUser($id: ID!) {
  deactivateUser(id: $id) {
    id
    isInactive
  }
}
`;

type DeactivateUserResponse = {
  deactivateUser: {
    id: string;
    isInactive: boolean;
  } | null;
}

/* Activate User Mutation */
const ACTIVATE_USER = gql`
mutation activateUser($id: ID!) {
  activateUser(id: $id) {
    id
    isInactive
  }
}
`;

type ActivateUserResponse = {
  activateUser: {
    id: string;
    isInactive: boolean;
  } | null;
}

/* Impersonate User Mutation */
const IMPERSONATE_USER = gql`
mutation impersonateUser($userId: ID!, $reason: String!) {
  success: impersonateUser(userId: $userId, reason: $reason)
}
`;

type ImpersonateUserInput = {
  reason: string;
}

const states = ["AL", "AK", "AS", "AZ", "AR", "CA", "CO", "CT", "DE", "DC", "FM", "FL", "GA", "GU", "HI", "ID", "IL", "IN", "IA", "KS", "KY", "LA", "ME", "MH", "MD", "MA", "MI", "MN", "MS", "MO", "MT", "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "MP", "OH", "OK", "OR", "PW", "PA", "PR", "RI", "SC", "SD", "TN", "TX", "UT", "VT", "VI", "VA", "WA", "WV", "WI", "WY"];

export const noteEditorConfig: ToolbarButtonConfig = {
  undo: true,
  redo: true,
  bold: true,
  italic: true,
  underline: true,
  strikethrough: false,
  unorderedList: true,
  orderedList: true,
  quote: true,
  code: false,
  subscript: false,
  superscript: false,
  clearFormat: true,
  alignment: false,
  headings: false,
  link: true,
  youtube: false,
  infoPanel: false,
  image: false,
  table: false,
}

export default function UserDetails() {
  const navigate = useNavigate();
  const { addAlert } = useAlertState();
  const { state } = useAuthState();
  const { userId } = useParams();
  const [roleChangeId, setRoleChangeId] = useState<string | null>(null);
  const [editNoteId, setEditNoteId] = useState<string | null>(null);
  const [editNoteContent, setEditNoteContent] = useState<string | null>(null);
  const [changeLogsPage, setChangeLogsPage] = useState<number>(0);
  const { data: userData, loading: userIsLoading } = useQuery<UserProfileResponse>(USER_PROFILE_QUERY, {
    variables: {
      id: userId,
    },
  });
  const { data: userNotesData, loading: userNotesAreLoading } = useQuery<UserNotesResponse>(USER_NOTES_QUERY, {
    variables: {
      userId: userId,
    },
  });
  const { data: userLogsData, loading: userLogsAreLoading } = useQuery<UserChangeLogsResponse>(USER_CHANGE_LOGS_QUERY, {
    variables: {
      id: userId,
      page: changeLogsPage,
    },
  });
  const [requestPasswordReset, { loading: requestPasswordResetIsLoading }] = useMutation<RequestPasswordResetResponse>(REQUEST_PASSWORD_RESET);
  const [forceChangePassword, { loading: forceChangePasswordIsLoading }] = useMutation<ForceChangePasswordResponse>(FORCE_CHANGE_PASSWORD);
  const [editName] = useMutation<EditNameResponse>(EDIT_NAME);
  const [editEmail] = useMutation<EditEmailResponse>(EDIT_EMAIL);
  const [editPhone] = useMutation<EditPhoneResponse>(EDIT_PHONE);
  const [editBirthdate] = useMutation<EditBirthdateResponse>(EDIT_BIRTHDATE);
  const [editAddress] = useMutation<EditAddressResponse>(EDIT_ADDRESS);
  const [createNote] = useMutation<CreateNoteResponse>(CREATE_NOTE, {
    update(cache, { data }) {
      if (!data?.createUserNote) {
        return;
      }

      cache.modify({
        fields: {
          userNotes(existingNotes = []) {
            const newNoteRef = cache.writeFragment({
              data: data.createUserNote,
              fragment: gql`
                fragment NewNote on UserNote {
                  id
                  content
                  created
                  createdBy {
                    id
                    firstName
                    lastName
                  }
                  lastUpdated
                  lastUpdatedBy {
                    id
                    firstName
                    lastName
                  }
                }
              `
            });

            return [newNoteRef, ...existingNotes];
          }
        }
      });
    },
  });
  const [editNote] = useMutation<EditNoteResponse>(EDIT_NOTE);
  const [deleteNote] = useMutation<DeleteNoteResponse>(DELETE_NOTE, {
    update(cache, { data }, { variables }) {
      if (!data?.success || !variables?.id) {
        return;
      }

      cache.modify({
        fields: {
          userNotes(existingNotes = [], { readField }) {
            return existingNotes.filter((noteRef: Reference) => variables.id !== readField('id', noteRef));
          }
        }
      });
    },
  });
  const [getOrgPlans, { data: orgPlansData, loading: orgPlansAreLoading }] = useLazyQuery<GetOrgPlansResponse>(GET_ORG_PLANS);
  const [addProductRole] = useMutation<AddProductRoleResponse>(ADD_PRODUCT_ROLE, {
    update(cache, { data }, { variables }) {
      if (!userData?.user || !variables?.productId) {
        return;
      }

      cache.modify({
        id: cache.identify(userData.user),
        fields: {
          productRoles(existingRoles = [], { readField }) {
            const newRoleRef = cache.writeFragment({
              data: data?.addUserProductAccess,
              fragment: gql`
                fragment NewRole on ProductRole {
                  id
                  name
                  description
                }
              `
            });

            return [...existingRoles, newRoleRef];
          }
        }
      });
    },
  });
  const [editProductRole] = useMutation<EditProductRoleResponse>(EDIT_PRODUCT_ROLE, {
    update(cache, { data }, { variables }) {
      if (!userData?.user || !variables?.productId) {
        return;
      }

      cache.modify({
        id: cache.identify(userData.user),
        fields: {
          productRoles(existingRoles = [], { readField }) {
            const newRoleRef = cache.writeFragment({
              data: data?.editUserProductAccess,
              fragment: gql`
                fragment NewRole on ProductRole {
                  id
                  name
                  description
                }
              `
            });

            const prevRoles = existingRoles.filter((roleRef: Reference) => {
              const roleId = readField('id', roleRef);

              const plan = orgPlansData?.org?.plans.find((plan) => {
                for (const role of plan.plan.product.roles) {
                  if (role.id === roleId) {
                    return true;
                  }
                }

                return false;
              });

              if (plan?.plan.product.id === variables.productId) {
                return false;
              }

              return true;
            });

            return [...prevRoles, newRoleRef];
          }
        }
      });
    },
    optimisticResponse(vars) {
      const unsuccessfulResponse = {
        editUserProductAccess: null,
      };

      if (!vars?.productId || !vars?.roleId) {
        return unsuccessfulResponse;
      }

      const plan = orgPlansData?.org?.plans.find((plan) => plan.plan.product.id === vars.productId);
      if (!plan) {
        return unsuccessfulResponse;
      }

      const role = plan.plan.product.roles.find((role) => role.id === vars.roleId);
      if (!role) {
        return unsuccessfulResponse;
      }

      return {
        editUserProductAccess: role,
      }
    },
  });
  const [removeProductRole] = useMutation<RemoveProductRoleResponse>(REMOVE_PRODUCT_ROLE, {
    update(cache, { data }, { variables }) {
      if (!userData?.user || !variables?.productId) {
        return;
      }

      cache.modify({
        id: cache.identify(userData.user),
        fields: {
          productRoles(existingRoles = [], { readField }) {
            return existingRoles.filter((roleRef: Reference) => {
              const roleId = readField('id', roleRef);

              const plan = orgPlansData?.org?.plans.find((plan) => {
                for (const role of plan.plan.product.roles) {
                  if (role.id === roleId) {
                    return true;
                  }
                }

                return false;
              });

              if (plan?.plan.product.id === variables.productId) {
                return false;
              }

              return true;
            });
          }
        }
      });
    },
    optimisticResponse: {
      success: true
    },
  });
  const [deleteUser] = useMutation<DeleteUserResponse>(DELETE_USER);
  const [addUserToOrg] = useMutation<AddUserToOrgResponse>(ADD_USER_TO_ORG, {
    refetchQueries: [GET_ORG_PLANS],
  });
  const [removeFromOrg] = useMutation<RemoveUserFromOrgResponse>(REMOVE_USER_FROM_ORG, {
    update(cache, { data }) {
      if (!data?.removeUserFromOrg || !userData?.user) {
        return;
      }

      if (data.removeUserFromOrg.id) {
        // The user was not successfully removed
        return;
      }

      cache.modify({
        id: cache.identify(userData.user),
        fields: {
          org() {
            return null;
          }
        }
      });
    }
  });
  const [deactivateUser] = useMutation<DeactivateUserResponse>(DEACTIVATE_USER);
  const [activateUser] = useMutation<ActivateUserResponse>(ACTIVATE_USER);
  const [impersonateUser] = useMutation(IMPERSONATE_USER);

  useEffect(() => {
    if (!userData?.user?.org?.id) {
      return;
    }

    getOrgPlans({
      variables: {
        orgId: userData.user.org.id,
      },
    });
  }, [userData?.user?.org?.id, getOrgPlans]);

  /* Request Password Reset */
  const handleRequestPasswordReset = async () => {
    if (!userData?.user) {
      return;
    }

    const { data } = await requestPasswordReset({
      variables: {
        email: userData.user.email,
      },
    });

    if (data?.success) {
      const id = generateId();
      const alert = <StandardAlert title='Password reset sent' type='success' id={id} />

      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Password reset failed' type='error' id={id} />

      addAlert(id, alert, 60);
    }
  }

  /* Force Change Password */
  const handleForceChangePassword = async (values: ForceChangePasswordInput) => {
    if (!userData?.user) {
      return;
    }

    const { data, errors } = await forceChangePassword({
      variables: {
        input: {
          id: userId,
          password: values.password,
        },
      },
    });

    if (data?.success) {
      forceChangePasswordForm.resetValues();

      const id = generateId();
      const alert = <StandardAlert title='Password changed' type='success' id={id} />

      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Password reset failed' description={errors ? errors[0].message : undefined} type='error' id={id} />

      addAlert(id, alert, 60);
    }
  }

  const validatePassword = (_: string, value: string) => {
    if (value === '') {
      return '';
    }

    if (value.length < 8) {
      return 'Password must be at least 8 characters';
    }

    if (value === value.toLowerCase()) {
      return 'Password must contain at least one uppercase letter';
    }

    if (value === value.toUpperCase()) {
      return 'Password must contain at least one lowercase letter';
    }

    const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/
    if (!specialCharacters.test(value)) {
      return 'Password must contain at least one special character';
    }

    return null;
  }

  const forceChangePasswordForm = useForm<ForceChangePasswordInput>({
    initialValues: {
      password: '',
    },
    onSubmit: handleForceChangePassword,
  });

  /* Edit Name */
  const handleEditName = async (values: EditNameInput) => {
    await editName({
      variables: {
        id: userId,
        firstName: values.firstName,
        lastName: values.lastName,
      },
    });
  }

  const editNameModal = (
    <FormModal<EditNameInput> title='Edit Name' onSubmit={handleEditName} initialValues={{ firstName: userData?.user?.firstName || '', lastName: userData?.user?.lastName || '' }}>
      <View style={{ flexDirection: 'row', gap: '16px' }}>
        <TextField label='First Name' name='firstName' required />
        <TextField label='Last Name' name='lastName' required />
      </View>
    </FormModal>
  );

  /* Edit Email */
  const handleEditEmail = async (values: EditEmailInput) => {
    const { errors } = await editEmail({
      variables: {
        id: userId,
        email: values.email,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update email' description={errors[0].message} type='error' id={id} />

      addAlert(id, alert, 60);
    }
  }

  const editEmailModal = (
    <FormModal<EditEmailInput> title='Edit Email' onSubmit={handleEditEmail} initialValues={{ email: userData?.user?.email || '' }}>
      <View style={{ gap: '16px' }} >
        <TextField label='Email' name='email' required />
        <InfoPanel type='warning'>
          <View style={{ gap: '8px' }}>
            <StyledParagraph>The user must use the new email to login to their account.</StyledParagraph>
            <StyledParagraph bold>You must have permission to change this.</StyledParagraph>
          </View>
        </InfoPanel>
      </View>
    </FormModal>
  );

  /* Edit Phone */
  const handleEditPhone = async (values: EditPhoneInput) => {
    const { errors } = await editPhone({
      variables: {
        id: userId,
        phone: values.phone,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to update phone' description={errors[0].message} type='error' id={id} />

      addAlert(id, alert, 60);
    }
  }

  const editPhoneModal = (
    <FormModal<EditPhoneInput> title='Edit Phone' onSubmit={handleEditPhone} initialValues={{ phone: userData?.user?.phone || '' }}>
      <View style={{ gap: '16px' }} >
        <PhoneInputField label='Phone' name='phone' required />
      </View>
    </FormModal>
  );

  /* Edit Birthdate */
  const handleEditBirthdate = async (values: EditBirthdateInput) => {
    await editBirthdate({
      variables: {
        id: userId,
        dateOfBirth: values.dateOfBirth,
      },
    });
  }

  const editBirthdateModal = (
    <FormModal<EditBirthdateInput> title='Edit Birthdate' onSubmit={handleEditBirthdate} initialValues={{ dateOfBirth: userData?.user?.dateOfBirth || '' }}>
      <BirthdayPicker label='Birthdate' name='dateOfBirth' />
    </FormModal>
  );

  /* Edit Address */
  const handleEditAddress = async (values: EditAddressInput) => {
    await editAddress({
      variables: {
        id: userId,
        streetAddress: values.streetAddress,
        city: values.city,
        state: values.state,
        zip: values.zipcode,
      },
    });
  }

  const editAddressModal = (
    <FormModal<EditAddressInput> title='Edit Address' onSubmit={handleEditAddress} initialValues={{ streetAddress: userData?.user?.address?.streetAddress || '', city: userData?.user?.address?.city || '', state: userData?.user?.address?.state || '', zipcode: userData?.user?.address?.zipcode || '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Street Address' name='streetAddress' required />
        <View style={{ flexDirection: 'row', gap: '16px' }}>
          <TextField label='City' name='city' required />
          <SingleSelect label='State' name='state' required>
            {states.map((state) => (
              <Choice label={state} value={state} key={state} />
            ))}
          </SingleSelect>
        </View>
        <TextField label='Zipcode' name='zipcode' required style={{ width: '50%' }} />
      </View>
    </FormModal>
  );

  /* Create Note */
  const handleCreateNote = async (values: CreateNoteInput) => {
    await createNote({
      variables: {
        userId: userId,
        content: values.content,
      },
    });
  }

  const createNoteModal = (
    <FormModal<CreateNoteInput> title='Create Note' submitLabel='Create' onSubmit={handleCreateNote} initialValues={{ content: '' }}>
      <TextEditor name='content' isEditMode buttonConfig={noteEditorConfig} />
    </FormModal>
  );

  /* Edit Note */
  const handleEditNote = async () => {
    if (!editNoteId || !editNoteContent) {
      setEditNoteContent(null);
      setEditNoteId(null);
      return;
    }

    await editNote({
      variables: {
        id: editNoteId,
        content: editNoteContent,
      },
    });

    setEditNoteContent(null);
    setEditNoteId(null);
  }

  /* Delete Note */
  const handleDeleteNote = async (id: string) => {
    await deleteNote({
      variables: {
        id: id,
      },
    });
  }

  const deleteNoteModal = (
    <ConfirmModal title='Delete note?' confirmLabel='Delete' onConfirm={handleDeleteNote} destructive>
      <StyledParagraph>This note will be permanently deleted.</StyledParagraph>
    </ConfirmModal>
  );

  /* Add Product Role */
  const handleAddProductRole = async (values: AddProductRoleInput) => {
    const { errors } = await addProductRole({
      variables: {
        userId: userId,
        productId: values.productId,
        roleId: values.roleId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to add role' description={errors[0].message} type='error' id={id} />

      addAlert(id, alert, 60);
    }
  }

  const addProductRoleModal = (
    <FormModal<AddProductRoleInput> title='Add Role' subtitle='Give the user access to a product' submitLabel='Add Role' onSubmit={handleAddProductRole} initialValues={{ productId: '', roleId: '' }}>
      <FormModalValueProvider>
        {({ getValue, setError }) => (
          <>
            {(() => {
              if (!getValue) {
                return <></>;
              }

              const plans = orgPlansData?.org?.plans.filter((plan) => {
                for (const role of plan.plan.product.roles) {
                  if (userData?.user?.productRoles.find((userRole) => userRole.id === role.id)) {
                    return false;
                  }
                }

                return true;
              });

              const selectedProduct = orgPlansData?.org?.plans?.find((plan) => plan.plan.product.id === getValue('productId'));
              const selectedRole = selectedProduct?.plan.product.roles.find((role) => role.id === getValue('roleId'));

              if (!plans || plans?.length === 0) {
                return (
                  <>
                    <StyledParagraph>This org is not subscribed to any products that the user does not have access to.</StyledParagraph>
                    <TextField name='' required style={{ display: 'none' }} />
                  </>
                );
              }

              return (
                <View style={{ gap: '32px' }}>
                  <View style={{ flexDirection: 'row', gap: '16px', '@media (max-width: 767px)': { flexDirection: 'column' } }}>
                    <SingleSelect label='Product' name='productId' style={{ maxWidth: 'calc(50% - 16px)', width: '50%', '@media (max-width: 767px)': { maxWidth: '100%', width: '100%' } }} validate={() => { setError && setError('roleId', ''); return null; }} required>
                      {orgPlansData?.org?.plans.filter((plan) => {
                        for (const role of plan.plan.product.roles) {
                          if (userData?.user?.productRoles.find((userRole) => userRole.id === role.id)) {
                            return false;
                          }
                        }

                        return true;
                      }).map((plan) => (
                        <Choice label={plan.plan.product.name} value={plan.plan.product.id.toString()} key={plan.plan.id} />
                      ))}
                    </SingleSelect>

                    {selectedProduct && (
                      <SingleSelect label='Role' name='roleId' style={{ width: '50%', '@media (max-width: 767px)': { width: '100%' } }} required>
                        {selectedProduct.plan.product.roles.map((role) => (
                          <Choice label={role.name} description={role.description || undefined} value={role.id.toString()} key={role.id} />
                        ))}
                      </SingleSelect>
                    )}
                  </View>

                  {(selectedProduct?.plan.costType === 'PER_USER' && selectedRole?.isBillable) &&
                    <InfoPanel type='warning'>
                      <View style={{ gap: '16px' }}>
                        <StyledParagraph>Giving this user access to this product will increase the org's billing costs by {selectedProduct.plan.cost} / {selectedProduct.plan.type === 'MONTHLY' ? 'month' : 'year'}.</StyledParagraph>
                        <StyledParagraph bold>You must have permission from an org manager to make this change.</StyledParagraph>
                      </View>
                    </InfoPanel>
                  }

                  {selectedProduct &&
                    <InfoPanel type='info'>
                      <View style={{ gap: '16px' }}>
                        {selectedProduct.plan.userLimit === null ?
                          <StyledParagraph>The org has <span style={{ fontWeight: 600 }}>unlimited seats remaining</span> for this product.</StyledParagraph>
                          :
                          <StyledParagraph>The org has <span style={{ fontWeight: 600 }}>{selectedProduct.plan.userLimit - selectedProduct.countBillableUsers} seats remaining</span> for this product.</StyledParagraph>
                        }
                      </View>
                    </InfoPanel>
                  }
                </View>
              );
            })()}
          </>
        )}
      </FormModalValueProvider>
    </FormModal>
  );

  /* Change Product Role */
  const handleChangeProductRole = (_: string, roleId: string | null) => {
    if (!roleId) {
      return;
    }

    const plan = orgPlansData?.org?.plans.find((plan) => {
      for (const role of plan.plan.product.roles) {
        if (role.id === roleId) {
          return true;
        }
      }

      return false;
    });
    if (!plan) {
      return;
    }

    const previousRoleId = userData?.user?.productRoles.find((role) => {
      for (const productRole of plan.plan.product.roles) {
        if (productRole.id === role.id) {
          return true;
        }
      }

      return false;
    });
    if (!previousRoleId) {
      return;
    }

    const previousRole = plan.plan.product.roles.find((role) => role.id === previousRoleId.id);
    if (!previousRole) {
      return;
    }

    const newRole = plan.plan.product.roles.find((role) => role.id === roleId);
    if (!newRole) {
      return;
    }

    // If changing from a non-billable role to a billable role, we prompt the user to confirm the change
    if (!previousRole.isBillable && newRole.isBillable) {
      setRoleChangeId(roleId);
      return;
    }

    // If the user is already on a billable role or is changing to a non-billable role, then no confirmation is necessary
    handleConfirmChangeRole(roleId);
  }

  const handleConfirmChangeRole = async (roleId: string) => {
    if (!roleId) {
      return;
    }

    const plan = orgPlansData?.org?.plans.find((plan) => {
      for (const role of plan.plan.product.roles) {
        if (role.id === roleId) {
          return true;
        }
      }

      return false;
    });

    if (!plan) {
      return;
    }

    const { errors } = await editProductRole({
      variables: {
        userId: userId,
        productId: plan.plan.product.id,
        roleId: roleId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to change role' type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Role updated' type='success' id={id} />
      addAlert(id, alert);
    }

    setRoleChangeId(null);
  }

  const confirmChangeRoleModal = () => {
    const selectedProduct = orgPlansData?.org?.plans.find((plan) => {
      for (const role of plan.plan.product.roles) {
        if (role.id === roleChangeId) {
          return true;
        }
      }

      return false;
    });

    return (
      <ConfirmModal title='Change Role?' confirmLabel='Confirm Change' onConfirm={async () => { await handleConfirmChangeRole(roleChangeId || ''); }} onCancel={() => { setRoleChangeId(null); }}>
        <View style={{ gap: '16px' }}>
          <StyledParagraph>You are changing from a non-billable to a billable role.</StyledParagraph>

          <InfoPanel type='warning'>
            <View style={{ gap: '16px' }}>
              <StyledParagraph>Changing this user's role will increase your billing costs by {selectedProduct?.plan.cost} / {selectedProduct?.plan.type === 'MONTHLY' ? 'month' : 'year'}.</StyledParagraph>
            </View>
          </InfoPanel>
        </View>
      </ConfirmModal>
    );
  }

  /* Remove Product Role */
  const handleRemoveProductRole = async (roleId: string) => {
    const plan = orgPlansData?.org?.plans.find((plan) => {
      for (const role of plan.plan.product.roles) {
        if (role.id === roleId) {
          return true;
        }
      }

      return false;
    });

    if (!plan) {
      return;
    }

    const { errors } = await removeProductRole({
      variables: {
        userId: userId,
        productId: plan.plan.product.id,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to remove role' type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const removeProductRoleModal = (
    <ConfirmModal title='Remove role?' confirmLabel='Remove' onConfirm={handleRemoveProductRole}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This role will be removed from the user and they will no longer have access to this product.</StyledParagraph>
        <StyledParagraph>The org will no longer be charged for this user's seat. If this is a billable role, the org will receive a prorated credit on the next invoice.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Delete User */
  const handleDeleteUser = async (values: DeleteUserInput) => {
    if (values.verification !== `I want to delete the account of ${userData?.user?.firstName} ${userData?.user?.lastName}`) {
      return;
    }

    const { data, errors } = await deleteUser({
      variables: {
        id: userId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to delete user' description={errors[0].message} type='error' id={id} />

      addAlert(id, alert, 60);
    }

    if (data?.success) {
      navigate('/users');
    }
  }

  const deleteUserModal = (
    <FormModal<DeleteUserInput> title='Delete User' submitLabel='Delete User' onSubmit={handleDeleteUser} destructive initialValues={{ verification: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Type the below message' description={`I want to delete the account of ${userData?.user?.firstName} ${userData?.user?.lastName}`} name='verification' validate={(_: string, value: string) => {
          if (value === `I want to delete the account of ${userData?.user?.firstName} ${userData?.user?.lastName}`) {
            return null;
          }

          return 'Incorrect verification message';
        }} required />

        <InfoPanel type='warning'>
          <StyledParagraph bold>This will permanently delete the user's account.</StyledParagraph>
        </InfoPanel>
      </View>
    </FormModal>
  );

  /* Add To Org */
  const handleAddToOrg = async (values: AddToOrgInput) => {
    const { errors } = await addUserToOrg({
      variables: {
        userId: userId,
        orgId: values.orgId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to add to organization' description={errors[0].message} type='error' id={id} />

      addAlert(id, alert, 60);
    }
  }

  const addToOrgModal = (
    <FormModal<AddToOrgInput> title='Link Organization' submitLabel='Link Org' onSubmit={handleAddToOrg} initialValues={{ orgId: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Org ID' name='orgId' required />
      </View>
    </FormModal>
  );

  /* Remove From Org */
  const handleRemoveFromOrg = async () => {
    const { errors } = await removeFromOrg({
      variables: {
        userId: userId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to remove from organization' description={errors[0].message} type='error' id={id} />

      addAlert(id, alert, 60);
    }
  }

  const removeFromOrgModal = (
    <ConfirmModal title='Remove from organization?' confirmLabel='Remove' onConfirm={handleRemoveFromOrg} destructive>
      <StyledParagraph>This user will be removed from their organization and will lose access to all products.</StyledParagraph>
    </ConfirmModal>
  );

  /* Deactivate User */
  const handleDeactivateUser = async () => {
    const { errors } = await deactivateUser({
      variables: {
        id: userId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to deactivate user' description={errors[0].message} type='error' id={id} />

      addAlert(id, alert, 60);
    }
  }

  const deactivateUserModal = (
    <ConfirmModal title='Deactivate User?' confirmLabel='Deactivate' onConfirm={handleDeactivateUser} destructive>
      <StyledParagraph>The user's account will be deactivated. The user will not be able to login to their account and will lose access to all products.</StyledParagraph>
    </ConfirmModal>
  );

  /* Activate User */
  const handleActivateUser = async () => {
    const { errors } = await activateUser({
      variables: {
        id: userId,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to activate user' description={errors[0].message} type='error' id={id} />

      addAlert(id, alert, 60);
    }
  }

  const activateUserModal = (
    <ConfirmModal title='Activate User?' confirmLabel='Activate' onConfirm={handleActivateUser}>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>The user's account will be activated. The user will be able to login to their account and will regain access to all products.</StyledParagraph>
        <StyledParagraph bold>A seat will be added for all products that the user currently has access to.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Impersonate User */
  const handleImpersonateUser = async (values: ImpersonateUserInput) => {
    const { errors } = await impersonateUser({
      variables: {
        userId: userId,
        reason: values.reason,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to impersonate user' description={errors[0].message} type='error' id={id} />

      addAlert(id, alert, 60);
    } else {
      if (window.location.hostname.includes('-dev')) {
        window.open('https://start-dev.barscience.us');
      } else if (!window.location.hostname.includes('barscience.us')) {
        window.open(`http://${window.location.hostname}:6007`);
      } else {
        window.open('https://start.barscience.us');
      }
    }
  }

  const impersonateUserModal = (
    <FormModal<ImpersonateUserInput> title='Impersonate User' onSubmit={handleImpersonateUser} submitLabel='Impersonate User' initialValues={{ reason: '' }}>
      <View style={{ gap: '16px' }}>
        <TextArea label='Reason' name='reason' required />
        <InfoPanel type='warning'>
          <StyledParagraph>You will be logged into this users account. Any actions you take will effect their real data and be as if the user took them themselves.</StyledParagraph>
        </InfoPanel>
      </View>
    </FormModal>
  );

  if (!state.user?.bsEmployeePermissions?.canLookupUsers) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (userIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <AtomSpinner size='large' />
        </Cell>
      </StandardGrid>
    );
  }

  if (!userData?.user) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      {roleChangeId !== null &&
        <ModalLauncher modal={confirmChangeRoleModal()}>
          {({ openModal }) => (
            <AutoOpen openModal={openModal} />
          )}
        </ModalLauncher>
      }

      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Users' to='/users' />
          <Breadcrumb label={`${userData.user.firstName} ${userData.user.lastName}`} />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
          <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
            <StyledHeading tag='h3'>{userData.user.firstName} {userData.user.lastName}</StyledHeading>
            {userData.user.isBSEmployee && <StyledParagraph bold style={{ backgroundColor: Colors.primary500, borderRadius: '4px', color: Colors.shades0, fontSize: '12px', padding: '4px 8px' }}>Bar Science Employee</StyledParagraph>}
            {userData.user.isSupportAgent && <StyledParagraph bold style={{ backgroundColor: Colors.primary500, borderRadius: '4px', color: Colors.shades0, fontSize: '12px', padding: '4px 8px' }}>Support Agent</StyledParagraph>}
          </View>

          <HasEmployeePermission permissions={['canImpersonateUsers']}>
            <ModalLauncher modal={impersonateUserModal}>
              {({ openModal }) => (
                <Button label='Impersonate User' variant='secondary' role='button' action={openModal} />
              )}
            </ModalLauncher>
          </HasEmployeePermission>
        </View>
      </Cell>
      <Row>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Profile Info</StyledHeading>
              <View>
                <StyledCaption>Name</StyledCaption>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph>{userData.user.firstName} {userData.user.lastName}</StyledParagraph>
                  <HasEmployeePermission permissions={['canEditUserProfiles']}>
                    <ModalLauncher modal={editNameModal}>
                      {({ openModal }) => (
                        <Button label='Edit' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} />
                      )}
                    </ModalLauncher>
                  </HasEmployeePermission>
                </View>
              </View>
              <View>
                <StyledCaption>Email</StyledCaption>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph>{userData.user.email}</StyledParagraph>
                  <HasEmployeePermission permissions={['canChangeUserPasswords']}>
                    <ModalLauncher modal={editEmailModal}>
                      {({ openModal }) => (
                        <Button label='Edit' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} />
                      )}
                    </ModalLauncher>
                  </HasEmployeePermission>
                </View>
              </View>
              <View>
                <StyledCaption>Phone</StyledCaption>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph>{userData.user.phone}</StyledParagraph>
                  <HasEmployeePermission permissions={['canEditUserProfiles']}>
                    <ModalLauncher modal={editPhoneModal}>
                      {({ openModal }) => (
                        <Button label='Edit' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} />
                      )}
                    </ModalLauncher>
                  </HasEmployeePermission>
                </View>
              </View>
              <View>
                <StyledCaption>Address</StyledCaption>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph>{userData.user.address?.streetAddress}</StyledParagraph>
                  <HasEmployeePermission permissions={['canEditUserProfiles']}>
                    <ModalLauncher modal={editAddressModal}>
                      {({ openModal }) => (
                        <Button label='Edit' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} />
                      )}
                    </ModalLauncher>
                  </HasEmployeePermission>
                </View>
                <StyledParagraph>{userData.user.address?.city}, {userData.user.address?.state} {userData.user.address?.zipcode}</StyledParagraph>
              </View>
              <View>
                <StyledCaption>Date of Birth</StyledCaption>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledParagraph>{userData.user.dateOfBirth}</StyledParagraph>
                  <HasEmployeePermission permissions={['canEditUserProfiles']}>
                    <ModalLauncher modal={editBirthdateModal}>
                      {({ openModal }) => (
                        <Button label='Edit' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} />
                      )}
                    </ModalLauncher>
                  </HasEmployeePermission>
                </View>
              </View>
            </View>
          </Card>
        </Cell>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Org Info</StyledHeading>
              {userData.user.org ?
                <>
                  <View>
                    <StyledCaption>Name</StyledCaption>
                    <Link href={'/orgs/' + userData.user.org.id}><StyledParagraph>{userData.user.org.name}</StyledParagraph></Link>
                  </View>
                  <View>
                    <StyledCaption>ID</StyledCaption>
                    <StyledParagraph>{userData.user.org.id}</StyledParagraph>
                  </View>
                  <ModalLauncher modal={removeFromOrgModal}>
                    {({ openModal }) => (
                      <Button label='Remove from org' variant='tertiary' role='button' action={openModal} disabled={!state.user?.bsEmployeePermissions?.canEditUserProfiles || userData.user?.org?.primaryContact?.id === userData.user?.id || userData.user?.org?.billingContact?.id === userData.user?.id} destructive />
                    )}
                  </ModalLauncher>
                </>
                :
                <>
                  <StyledParagraph>This user is not part of an organization.</StyledParagraph>
                  <ModalLauncher modal={addToOrgModal}>
                    {({ openModal }) => (
                      <Button label='Link Organization' variant='tertiary' role='button' action={openModal} disabled={!state.user?.bsEmployeePermissions?.canEditUserProfiles} />
                    )}
                  </ModalLauncher>
                </>
              }
            </View>
          </Card>
        </Cell>
      </Row>
      <Row>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Account Info</StyledHeading>
              <View>
                <StyledCaption>Status</StyledCaption>
                {userData.user.isInactive ?
                  <View style={{ flexDirection: 'row', gap: '16px' }}>
                    <StyledParagraph style={{ color: Colors.error500, fontWeight: 600 }}>INACTIVE</StyledParagraph>
                    <ModalLauncher modal={activateUserModal}>
                      {({ openModal }) => (
                        <Button label='Activate' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} disabled={!state.user?.bsEmployeePermissions?.canEditUserProductAccess} />
                      )}
                    </ModalLauncher>
                  </View>
                  :
                  <View style={{ flexDirection: 'row', gap: '16px' }}>
                    <StyledParagraph style={{ color: Colors.primary500, fontWeight: 600 }}>ACTIVE</StyledParagraph>
                    <ModalLauncher modal={deactivateUserModal}>
                      {({ openModal }) => (
                        <Button label='Deactivate' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} destructive disabled={!state.user?.bsEmployeePermissions?.canEditUserProductAccess} />
                      )}
                    </ModalLauncher>
                  </View>
                }
              </View>
              <View>
                <StyledCaption>Created</StyledCaption>
                <StyledParagraph>{new Date(userData.user.created).toLocaleString()}</StyledParagraph>
              </View>
              <View>
                <StyledCaption>Last Login</StyledCaption>
                <StyledParagraph>{userData.user.lastLoginTimestamp ? new Date(userData.user.lastLoginTimestamp).toLocaleString() : '----'}</StyledParagraph>
              </View>
              <View>
                <StyledCaption>Last Login IP</StyledCaption>
                <StyledParagraph>{userData.user.lastLoginIp || '----'}</StyledParagraph>
              </View>
            </View>
          </Card>
        </Cell>
        <Cell lg={6} md={4} sm={4}>
          <Card size='medium'>
            <View style={{ gap: '16px' }}>
              <StyledHeading tag='h6'>Password</StyledHeading>
              <View style={{ alignItems: 'flex-start', flexDirection: 'row', gap: '16px' }}>
                <TextField label='' name='password' type='password' value={forceChangePasswordForm.values.password} error={forceChangePasswordForm.errors.password} validate={validatePassword} onChange={forceChangePasswordForm.handleChange} onValidate={forceChangePasswordForm.handleValidate} disabled={(userData.user.isBSEmployee && !state.user?.bsEmployeePermissions?.canManageBSEmployees) || (!userData.user.isBSEmployee && !state.user?.bsEmployeePermissions?.canChangeUserPasswords)} style={{ maxWidth: '300px' }} required />
                <Button label='Force change password' variant='tertiary' role='button' action={forceChangePasswordForm.handleSubmit} loading={forceChangePasswordIsLoading} disabled={forceChangePasswordForm.hasError || (userData.user.isBSEmployee && !state.user?.bsEmployeePermissions?.canManageBSEmployees) || (!userData.user.isBSEmployee && !state.user?.bsEmployeePermissions?.canChangeUserPasswords)} style={{ marginTop: '3px' }} />
              </View>
              <Button label='Send password reset' variant='tertiary' role='button' action={handleRequestPasswordReset} loading={requestPasswordResetIsLoading} disabled={userData.user?.isInactive} />
            </View>
          </Card>
        </Cell>
      </Row>
      <Row>
        <Cell lg={6} md={8} sm={4}>
          <Card size='medium'>
            {(orgPlansAreLoading || userIsLoading) ?
              <View style={{ alignItems: 'center' }}>
                <CircularSpinner size='medium' />
              </View>
              :
              <View style={{ gap: '16px' }}>
                <View style={{ alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <StyledHeading tag='h6'>Product Access</StyledHeading>
                  <ModalLauncher modal={addProductRoleModal}>
                    {({ openModal }) => (
                      <Button label='Add Role' variant='tertiary' role='button' action={openModal} disabled={!state.user?.bsEmployeePermissions?.canEditUserProductAccess || userData.user?.isInactive} style={{ height: 'fit-content', margin: '0px' }} />
                    )}
                  </ModalLauncher>
                </View>
                {orgPlansData?.org?.plans.map((plan) => {
                  const userRole = userData?.user?.productRoles.find((role) => {
                    return plan.plan.product.roles.some((productRole) => productRole.id === role.id);
                  });

                  if (userRole === undefined) {
                    return <></>;
                  }

                  return (
                    <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px', '@media (max-width: 767px)': { flexWrap: 'wrap', gap: '8px' } }} key={plan.plan.id}>
                      <View style={{ flexDirection: 'row', gap: '8px', maxWidth: '140px', minWidth: '140px', width: '140px' }}>
                        <Icon icon={getProductIcon(plan.plan.product.id)} size='medium' />
                        <StyledParagraph bold>{plan.plan.product.name}</StyledParagraph>
                      </View>

                      <SingleSelect label='' name='' value={userRole.id.toString()} style={{ maxWidth: '300px' }} disabled={!state.user?.bsEmployeePermissions?.canEditUserProductAccess || userData.user?.isInactive} onChange={handleChangeProductRole} >
                        {plan.plan.product.roles.map((role) => (
                          <Choice label={role.name} value={role.id.toString()} description={role.description || undefined} key={role.id} />
                        ))}
                      </SingleSelect>

                      <ModalLauncher modal={removeProductRoleModal}>
                        {({ openModal }) => (
                          <Button label='Remove' variant='tertiary' role='button' action={() => { openModal(userRole.id); }} disabled={!state.user?.bsEmployeePermissions?.canEditUserProductAccess || userData.user?.isInactive} />
                        )}
                      </ModalLauncher>
                    </View>
                  );
                })}
              </View>
            }
          </Card>
        </Cell>
        <Cell lg={6} md={8} sm={4}>
          <Card size='medium'>
            {userNotesAreLoading ?
              <View style={{ alignItems: 'center' }}>
                <CircularSpinner size='medium' />
              </View>
              :
              <View>
                <View style={{ gap: '16px' }}>
                  <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                    <StyledHeading tag='h6'>Notes</StyledHeading>
                    <HasEmployeePermission permissions={['canEditUserProfiles']}>
                      <ModalLauncher modal={createNoteModal}>
                        {({ openModal }) => (
                          <Button label='Create Note' variant='tertiary' role='button' action={openModal} style={{ height: 'fit-content', margin: '0px' }} />
                        )}
                      </ModalLauncher>
                    </HasEmployeePermission>
                  </View>
                  {userNotesData?.userNotes.map((note) => (
                    <View key={note.id} style={{ alignItems: 'flex-start', borderBottom: `1px solid ${Colors.neutral300}`, flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '16px', gap: '8px' }}>
                      <View style={{ gap: '8px', width: '100%' }}>
                        <TextEditor key={note.id} name={'user-note-' + note.id} isEditMode={editNoteId === note.id} buttonConfig={noteEditorConfig} value={note.content} editorStyle={{ height: 'fit-content', minHeight: 'fit-content' }} onChange={(_, value: string) => { setEditNoteContent(value); }} />
                        <StyledCaption>Created {new Date(note.created).toLocaleString()} by {note.createdBy.firstName} {note.createdBy.lastName} {note.lastUpdated ? (`(Last updated ${new Date(note.lastUpdated).toLocaleString()} by ${note.lastUpdatedBy?.firstName} ${note.lastUpdatedBy?.lastName})`) : ''}</StyledCaption>
                        {editNoteId === note.id && <Button label='Save changes' variant='tertiary' role='button' action={handleEditNote} />}
                      </View>
                      {editNoteId !== note.id && <HasEmployeePermission permissions={['canEditUserProfiles']}>
                        <ModalLauncher modal={deleteNoteModal}>
                          {({ openModal: openDeleteModal }) => (
                            <ActionMenu alignment='right'>
                              <ActionItem label='Edit' onClick={() => { setEditNoteId(note.id); setEditNoteContent(note.content); }} />
                              <ActionItem label='Delete' onClick={() => { openDeleteModal(note.id); }} />
                            </ActionMenu>
                          )}
                        </ModalLauncher>
                      </HasEmployeePermission>}
                    </View>
                  ))}
                  {userNotesData?.userNotes.length === 0 && <StyledParagraph>There are no notes for this user.</StyledParagraph>}
                </View>
              </View>
            }
          </Card>
        </Cell>
      </Row>
      <Row>
        <Cell lg={6} md={8} sm={4}>
          <Card size='medium'>
            {userLogsAreLoading ?
              <View style={{ alignItems: 'center' }}>
                <CircularSpinner size='medium' />
              </View>
              :
              <View style={{ gap: '16px', maxWidth: '100%' }}>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <StyledHeading tag='h6'>Change Logs</StyledHeading>
                  <PageButtons currentPage={changeLogsPage} numPages={userLogsData?.userChangeLogs?.pages || 0} onPageChange={setChangeLogsPage} />
                </View>
                <View style={{ gap: '8px', maxWidth: '100%' }}>
                  {userLogsData?.userChangeLogs?.logs.map((log) => (
                    <View key={log.timestamp} style={{ borderBottom: `1px solid ${Colors.neutral300}`, flexDirection: 'row', gap: '8px', maxWidth: '100%', paddingBottom: '16px' }}>
                      <StyledParagraph style={{ flexGrow: 1 }}>{log.description}</StyledParagraph>
                      <StyledParagraph style={{ color: Colors.neutral700, flexGrow: 0, fontSize: '14px', maxWidth: '150px', minWidth: '150px', width: '150px', wordWrap: 'break-word' }}><TimeAgo date={log.timestamp} title={log.timestamp} /></StyledParagraph>
                    </View>
                  ))}
                </View>
                {userLogsData?.userChangeLogs?.logs.length === 0 && <StyledParagraph>There are no change logs for this user.</StyledParagraph>}
              </View>
            }
          </Card>
        </Cell>
      </Row>
      <HasEmployeePermission permissions={['canDeleteUsers']}>
        <Cell lg={12} md={8} sm={4}>
          <ModalLauncher modal={deleteUserModal}>
            {({ openModal }) => (
              <Button label='Delete User' variant='primary' role='button' action={openModal} destructive />
            )}
          </ModalLauncher>
        </Cell>
      </HasEmployeePermission>
    </StandardGrid>
  );
}

const getProductIcon = (id: string): Icons => {
  switch (id) {
    case 'org':
      return Icons.Organization;
    case 'training':
      return Icons.Training;
    case 'inventory':
      return Icons.Inventory;
    case 'scheduling':
      return Icons.Schedule;
    default:
      return Icons.AppGrid;
  }
}